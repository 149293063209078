<template>
  <div @mouseleave="isFocused = false" @mouseenter="isFocused = true">
    <AegeanLocationSearchList v-if="matchingLocations.length > 0" :locations="matchingLocations" @onListItemClick="onCustomListItemClick" />
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import SearchPortSelector from '@/components/search/desktop/SearchPortSelector';
import { notNull } from '@/logic/helpers/utils';
import AegeanLocationSearchList from '@/affiliates/aegeanair/components/search/common/AegeanLocationSearchList';

export default {
  name: 'AegeanSearchPortSelector',
  extends: SearchPortSelector,
  components: {
    AegeanLocationSearchList
  },
  emits: ['onPortSelectionChange', 'typehintUpdate'],
  methods: {
    /**
     * On Aegean, we overwrite this function in order to prevent selection of competing ports
     */
    onSearchInputKeyDown: function(index) {
      let topSuggestion = this.topSuggestion;
      if (notNull(topSuggestion)) {
        this.$emit('onPortSelectionChange', topSuggestion.LocationAbbr, this.target);
      }
    },
    /**
     * On Aegean, we overwrite this function in order to prevent selection of competing ports
     */
    onPortInputBlur: function(index) {
      // if the element is focused, then the blur event triggered by the search
      // input has been caused by a click on the suggestions list. Hence, the
      // hovered item should be selected.
      // If the element is not focused, then the user has just clicked somewhere
      // outside the search input, hence select the first suggestion from the
      // list
      if (this.isFocused === true) {
      } else {
        this.onSearchInputKeyDown(index);
      }
    },
    /**
     * On Aegean, we need this custom function to filter competing routes
     */
    onCustomListItemClick: function(location) {
      if (location.isConnectionPossible === true) {
        this.onSelect(location.LocationAbbr, location.alias);
      }
    },
    /**
     * For Aegean, we have to overwrite the updateTypehint action in order to make sure
     * that no competing ports are allowed
     */
    updateTypehint: function() {
      // when nothing has been typed, emit empty string
      if (this.filter === '') {
        // emit empty selection in order to reset the currently selected location
        this.$emit('onPortSelectionChange', '', this.target);
        // emit typehint update in order to clear the typehing
        this.$emit('typehintUpdate', '');
        return;
      }

      // on exact match, the typehint is not necessary, so emit empty string
      let exactMatch = this.matchingLocations.filter(location => {
        return location.alias === this.filter;
      });

      if (exactMatch.length > 0) {
        this.$emit('typehintUpdate', '');
        return;
      }

      // get aliases that start with exactly what the user is typing
      let possibleTypehints = this.matchingLocations.filter(location => {
        return location.alias.toLowerCase().startsWith(this.filter.toLowerCase());
      });

      if (possibleTypehints.length > 0) {
        // if the suggested typehint corresponds to a non-linked port, do
        // not show it
        if (false === possibleTypehints[0].isConnectionPossible) {
          this.$emit('typehintUpdate', '');
          return;
        }
        let hint = possibleTypehints[0].alias;
        var pattern = new RegExp(this.escapeRegExChars(this.filter), 'gi');
        this.$emit('typehintUpdate', hint.replace(pattern, this.filter));
        return;
      }

      this.$emit('typehintUpdate', '');
    }
  }
};
</script>
