<template>
  <div class="itinerary-header">
    <span>
      <JourneyTitle :ports="stops" v-if="indirect || isSuperPortSearch" :cropText="false" />
    </span>
    <span v-if="showTripRemarks" class="itinerary-header__indirect-trip-time">
      <small class="itinerary-header__label-fastest" v-if="fastest">{{ trans('fastest') }}</small>
      <FhTooltip :message="tripRemarksTooltip">
        <i :class="['fh', tripRemarksIcon]"> </i>
      </FhTooltip>
      {{ tripRemarks }}
    </span>
  </div>
</template>

<script>
import { message } from '@/logic/helpers/utils';
import JourneyTitle from '@/components/shared/JourneyTitle';
import { trans, shortDate, durationString } from '@/filters';

export default {
  name: 'AeItineraryHeader',
  props: ['fastest', 'departure', 'arrival', 'overnight', 'stops', 'indirect', 'duration', 'isSuperPortSearch'],
  components: { JourneyTitle },
  methods: {
    trans,
    shortDate,
    durationString
  },
  computed: {
    showTripRemarks: function () {
      return this.indirect || this.overnight || this.fastest;
    },
    // showRemarksIcon: function () {
    //   return this.indirect;
    // },
    tripRemarks: function () {
      return durationString(this.duration);
    },
    tripRemarksIcon: function () {
      return this.overnight ? 'fh-moon' : 'fh-clock';
    },
    tripRemarksTooltip: function () {
      return `${message('arrival')} ${shortDate(this.arrival)}`;
    },
  },
};
</script>
