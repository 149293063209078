<template>
  <div class="mt5">
    <h1 v-if="isHeader === true" class="search-box__header search-box__header--primary search-box__header--centered">{{ title }}</h1>
    <h4 v-else :class="['search-box__header search-box__header--centered', extraClass]">{{ title }} <InfoModalItem v-if="hasInfo" :slug="infoSlug" @openInfoModalClicked="openInfoModalClicked"></InfoModalItem></h4>
  </div>
</template>

<script>
export default {
  name: 'SearchSideboxHeader',
  props: {
    title: {
      default: '',
      type: String
    },
    isHeader: {
      default: false,
      type: Boolean
    },
    infoSlug: {
      default: '',
      type: String
    },

    hasExtraClass: {
      default: false,
      type: Boolean
    }
  },
  emits: ['openInfoModalClicked'],
  computed: {
    hasInfo() {
      return this.infoSlug !== '';
    },
    extraClass() {
      return this.hasExtraClass ? 'search-box__header--secondary' : null;
    }
  },
  methods: {
    openInfoModalClicked() {
      this.$emit('openInfoModalClicked');
    }
  }
};
</script>
