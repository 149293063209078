<template>
  <div class="container booking-flow__tab">
    <!-- passenger section header -->
    <AegeanBookingFlowSectionHeader :order="'2'" :title="trans('tabpassengerstitle')" id="passengers-tab-top" />

    <!-- Top filters and buttons -->
    <div class="row">
      <div class="col-xs-12" :class="{ 'fp-main-column': isMobile, 'col-sm-8 passengers-main-list fp-main-column': !isMobile }">
        <AegeanSeatingAppTopBar :isOccupied="isOccupied" :hasCarsRestriction="hasCarsRestriction" />

        <div class="ae-mt22" v-for="(trip, tripIndex) in trips" :key="'trip-seat-selection-' + tripIndex">
          <!-- trip header -->
          <div class="aegean-section-bar-results">
            <div class="bar">
              <span>{{ shortDate(trip.timings.DepartureDateTime) }} - {{ trip.timings.DepTime }}</span>
            </div>
          </div>

          <!-- trip passengers forms -->
          <div>
            <transition-group name="fade" tag="div" appear>
              <PassengerSeatingComponent
                v-for="(passenger, passengerIndex) in trip.passengers"
                :key="tripIndex + 'passenger-seating-' + passenger.passengerIndex"
                :trip="trip"
                :tripIndex="tripIndex"
                :passenger="passenger"
                :passengerIndex="passengerIndex"
                :isOccupied="isOccupied"
                @onLoyaltyChange="onLoyaltyChange"
                @onResidenceDiscountChange="onResidenceDiscountChange"
              />

              <VehicleSeatingComponent
                v-for="vehicle in trip.vehicles"
                :key="tripIndex + 'vehicle-seating-' + vehicle.vehicleIndex"
                :trip="trip"
                :tripIndex="tripIndex"
                :vehicle="vehicle"
                :isOccupied="isOccupied"
                :vehicleOptions="vehicleAccommodations"
                :passengers="passengers"
              />
            </transition-group>
          </div>
        </div>
      </div>
      <!-- / end of seat selection forms -->

      <!-- right side, seating analysis -->
      <div class="col-xs-12 col-sm-4">
        <div class="aegean-simple-bar">
          <span>{{ trans('seatselectionsandprices') }}</span>
        </div>

        <div class="cart-container">
          <div id="cart-selections-list-multi" v-if="!isMobile">
            <div v-for="(trip, tripIndex) in trips" class="cart-selection-item" :key="`cart-selection-item${tripIndex}`">
              <AegeanCartItem :trip="trip" :key="'cart-item-' + tripIndex" :showOverallPrice="true" :seats="trip.SeatingAnalysis"></AegeanCartItem>
              <AegeanPricingError :trip="trip" :key="'cart-item-trip-selections-warning-' + tripIndex" />
            </div>
          </div>

          <div class="cart-item-box">
            <div class="cart-item-heading final clearfix">
              <span class="cart-price-text pull-left">{{ trans('aegeanlabeltotalprice') }}</span>
              <span class="cart-price-text price pull-right" v-html="aegeanCurrency(overall)"></span>
            </div>
          </div>
        </div>

        <!-- Checkout buttons and messages -->
        <div v-if="hasVerifiedAvailability">
          <CheckoutSingleButtonComponent :checkoutButtonText="trans('proceedtobooking')" @onCheckoutClick="onFinalCheckout" />
        </div>
        <div v-else>
          <CheckoutButtonsComponent
            :backButtonText="trans('back')"
            :nextButtonText="trans('checkprices')"
            :isLadda="true"
            :isWaiting="isWaitingForPrices"
            :disable="isPricingDisabled"
            :showNextIcon="false"
            @onBackClicked="onBackClicked"
            @onNextClicked="onPricingRequested"
          />
          <small class="btn-block text-danger text-center mb20 mt20" v-if="hasConnectionError"> <i class="fh fh-unhappy" aria-hidden="true"></i> {{ trans('connectionerror') }} </small>
        </div>
      </div>
      <!-- /  end of trip seating analysis -->
    </div>
  </div>
</template>

<script>
import SeatingApp from '@/components/seats/SeatingApp';
import AegeanBookingFlowSectionHeader from '@/affiliates/aegeanair/components/shared/BookingFlowSectionHeader';
import AegeanCartItem from '@/affiliates/aegeanair/components/results/cart/AegeanCartItem';
import AegeanSeatingAppTopBar from '@/affiliates/aegeanair/components/seats/AegeanSeatingAppTopBar';
import AegeanPricingError from './AegeanPricingError';
import CheckoutButtonsComponent from '@/affiliates/aegeanair/components/shared/CheckoutButtons/CheckoutButtonsComponent';
import { trans, shortDate, aegeanCurrency } from '@/filters';

export default {
  extends: SeatingApp,
  components: {
    AegeanBookingFlowSectionHeader,
    AegeanCartItem,
    AegeanSeatingAppTopBar,
    AegeanPricingError,
    CheckoutButtonsComponent,
  },
  methods: {
    trans,
    shortDate,
    aegeanCurrency,
  },
};
</script>
