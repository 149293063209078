<template>
  <div>
    <p class="text-center mt40 mb10">
      <span class="text-5 text-center">
        {{ passengersString }}
      </span>
    </p>
    <div class="text-center">
      <i class="fh fh-minus icon-btn" :class="{ 'icon-btn--disabled': minPassengersReached }" @click="removePassenger()"> </i>
      <i class="fh fh-plus icon-btn" :class="{ 'icon-btn--disabled': maxPassengersReached }" @click="addPassenger()"> </i>
    </div>

    <hr />

    <p class="text-center">
      <span class="text-5 text-center">
        {{ vehiclesString }}
      </span>
    </p>
    <div class="text-center">
      <i class="fh fh-minus icon-btn" :class="{ 'icon-btn--disabled': minVehiclesReached }" @click="removeVehicle()"> </i>
      <i class="fh fh-plus icon-btn" :class="{ 'icon-btn--disabled': maxVehiclesReached }" @click="addVehicle()"> </i>
    </div>
  </div>
</template>



<script>
import emitter from '@/emitter';
import { mapGetters } from 'vuex';

export default {
  name: 'SearchTravelersPicker',
  methods: {
    addPassenger() {
      this.$store.dispatch('searchModule/increasePassengerCount');
      emitter.$emit('onSearchParametersChange');
    },
    addVehicle() {
      this.$store.dispatch('searchModule/increaseVehicleCount');
      emitter.$emit('onSearchParametersChange');
    },
    removePassenger() {
      this.$store.dispatch('searchModule/decreasePassengerCount');
      emitter.$emit('onSearchParametersChange');
    },
    removeVehicle() {
      this.$store.dispatch('searchModule/removeVehicle');
      emitter.$emit('onSearchParametersChange');
    },
  },
  computed: {
    ...mapGetters({
      passengersCount: 'searchModule/passengersCount',
      vehiclesCount: 'searchModule/vehiclesCount',
      maxPassengersReached: 'searchModule/maxPassengersReached',
      maxVehiclesReached: 'searchModule/maxVehiclesReached',
      minPassengersReached: 'searchModule/minPassengersReached',
      minVehiclesReached: 'searchModule/minVehiclesReached',
      passengersString: 'searchModule/passengersString',
      vehiclesString: 'searchModule/vehiclesString',
    }),
  },
};
</script>
