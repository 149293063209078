import '@/assets/sass/cobranded/aegean/aegeanferry.scss';
import '@/ferryhopper.config';

// set cobranded page flag to true
window.ferryhopper.cobranded_page = true;
window.ferryhopper.secondaryTracker = 'aegeanTracker';

import { createApp } from 'vue';

import { store } from '@/store/store';
import DesktopApp from '@/affiliates/aegeanair/AegeanFerryDesktopApp';

import LoadingSpinner from '@/components/shared/LoadingSpinner';
import InfoModal from '@/components/shared/modals/InfoModal';
import InfoModalItem from '@/components/shared/modals/InfoModalItem';
import JourneyTitle from '@/components/shared/JourneyTitle';
import FhModal from '@/components/shared/modals/FhModal';
import FhTooltip from '@/components/shared/FhTooltip';
import FhDropdown from '@/components/shared/FhDropdown/FhDropdown';
import FhCta from '@/components/shared/FhCta/FhCta';
import FhDropdownOption from '@/components/shared/FhDropdown/FhDropdownOption';
import ToggleSwitch from '@/components/shared/ToggleSwitch';
import FhRadio from '@/components/shared/FhRadio/FhRadio';
import Notification from '@/components/shared/Notification';
import FhInput from '@/components/shared/FhInput/FhInput';
import PortsRepository from '@/logic/repositories/PortsRepository';
import AeItinerary from '@/affiliates/aegeanair/components/results/itineraries/AeItinerary';
import { getInitialSearchState } from '../../logic/models/search/createInitialSearchState';
import dayjs from '@/logic/services/date/dateService';
import { getConfigurationValue } from '@/logic/helpers/utils';

let sharedComponents = {
  LoadingSpinner,
  InfoModal,
  InfoModalItem,
  JourneyTitle,
  FhModal,
  FhTooltip,
  FhDropdown,
  FhCta,
  FhDropdownOption,
  ToggleSwitch,
  FhRadio,
  Notification,
  FhInput,
  SelectableItinerary: AeItinerary,
};

const app = createApp(DesktopApp);
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.globalCobrandedVariant = 'aegeanair';
app.config.globalProperties.isMobile = getConfigurationValue('is_mobile', false);
app.config.globalProperties.isCobrandedPage = getConfigurationValue('cobranded_page', false);

app.use(store);
Object.entries(sharedComponents).forEach((entry) => app.component(entry[0], entry[1]));

PortsRepository.initialize(window.ferryhopper.ports).then(async () => {
  const initialSearchState = getInitialSearchState();
  await store.dispatch('searchModule/initializeSearch', initialSearchState);
  app.mount('#aegeanair-app-container');
});
