<template>
  <div class="ae-itinerary" ref="container" :class="{ selected: selected, available: trip.available }" @click="onClick">
    <AeItineraryHeader
      v-if="trip.isDirectTrip === false || isSuperPortSearch === true"
      :departure="segments[0].timings.DepartureDateTime"
      :arrival="arrival"
      :indirect="trip.isDirectTrip === false"
      :isSuperPortSearch="isSuperPortSearch"
      :duration="trip.duration"
      :overnight="isOvernight"
      :stops="trip.stops"
    />
    <div class="content clearfix">
      <div class="ae-itinerary-details-section pull-left">
        <AeItinerarySegment v-for="(segment, segmentIndex) in segments" :key="trip.id + segmentIndex" :segment="segment" />
      </div>

      <div class="ae-itinerary-price-section pull-right" :style="offsetStyle">
        <div :class="['ae-itinerary-price-box', { 'text-ellipsis': trip.available }]">
          <span class="price">
            <span v-if="trip.available" v-html="aegeanCurrency(trip.minPrice)"></span>
            <FhTooltip v-else :message="trip.unavailableReason">
              <i class="fh fh-negative mr10"></i>
            </FhTooltip>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AeItineraryHeader from '@/affiliates/aegeanair/components/results/itineraries/AeItineraryHeader';
import AeItinerarySegment from '@/affiliates/aegeanair/components/results/itineraries/AeItinerarySegment';
import { aegeanCurrency } from '@/filters';

export default {
  name: 'AegeanItinerary',
  components: {
    AeItineraryHeader,
    AeItinerarySegment,
  },
  emits: ['click'],
  props: {
    trip: Object,
    available: Boolean,
    selected: Boolean,
    isSuperPortSearch: Boolean,
  },
  computed: {
    offsetStyle() {
      if (this.isDirect === true) {
        return 'margin-top:0px;';
      } else {
        // the margin height required in order to align on each segment
        // eg. align with second segment in triple: margin-top:40px
        // eg. align between two segments in double: margin-top: 20px;
        let segmentHeight = 10;
        let segmentCount = this.trip.segments.length;
        let midHeight = (segmentCount - 1) * segmentHeight;
        if (segmentCount === 3) {
          midHeight = midHeight + 10;
        }
        return `margin-top:${midHeight}px`;
      }
    },
    isDirect() {
      return this.trip.isDirectTrip === true;
    },
    segments() {
      if (this.isDirect === true) {
        return [this.trip];
      } else {
        return this.trip.segments;
      }
    },
    arrival() {
      if (this.isDirect === true) {
        return this.trip.timings.ArrivalDateTime;
      } else {
        return this.trip.segments[this.trip.segments.length - 1].timings.ArrivalDateTime;
      }
    },
    isOvernight() {
      return this.trip.overnight;
    },
  },
  methods: {
    aegeanCurrency,
    onClick() {
      this.$emit('click', this.trip.id, this.trip.available);
    },
  },
};
</script>
