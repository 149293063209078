<template>
  <div v-if="hasValidQuickSearch" class="quick-search">
    <span class="quick-search__heading">{{ trans('gotoyourlastsearch') }}:</span>
    <JourneyTitle :ports="ports" classlist="quick-search__description" :cropText="false" />
    <div class="quick-search__footer clearfix">
      <button class="btn btn-md btn-ghost btn-ghost--primary quick-search__btn px25" @click.once="quickSearch()">{{ trans('quicksearchbtn') }}</button>
    </div>
  </div>
</template>

<script>
import JourneyTitle from '@/components/shared/JourneyTitle';
import { getSearchStatefromStorage } from '../../../logic/models/search/createInitialSearchState';
import { trans } from '@/filters';

export default {
  name: 'QuickSearchComponent',
  components: { JourneyTitle },
  data: () => ({
    savedSearch: getSearchStatefromStorage(),
  }),
  emits: ['performQuickSearch'],
  computed: {
    hasValidQuickSearch() {
      return this.savedSearch.isSavedSearch;
    },
    ports() {
      return this.savedSearch.selectedPorts.filter((o) => !!o.LocationAbbr).map((o) => o.short);
    },
  },
  methods: {
    trans,
    quickSearch: function () {
      this.$emit('performQuickSearch');
    },
  },
};
</script>
