<template>
  <div @mouseleave="isFocused = false" @mouseenter="isFocused = true">
    <div class="location-selection-list" v-if="matchingLocations.length > 0">
      <ReturnLocationsSearchList v-if="hasReturnLocations" :locations="matchingReturnLocations" :selectedPort="selectedPort" @onListItemClick="onSelect" />
      <PopularLocationsSearchList
        :locations="matchingLocations"
        :isPortsFiltered="filter !== ''"
        :isDirectPortsShown="isDirectPortsShown"
        :hasReturnLocations="hasReturnLocations"
        :siblingPort="siblingPort"
        :selectedPort="selectedPort"
        @onListItemClick="onSelect"
      />
      <AllLocationsSearchList :allLocations="matchingAllLocations" v-if="filter === ''" @onListItemClick="onSelect" />
    </div>
    <div class="location-selection-list" v-else>
      <NotFoundLocationSearchList v-if="this.filter !== ''" :lastSuggestion="lastSuggestion" :lastUserInput="filter" @onListItemClick="onSelect"></NotFoundLocationSearchList>
      <AllLocationsSearchList :hasOnlyAllCategory="hasOnlyAllCategory" :allLocations="matchingAllLocations" @onListItemClick="onSelect" />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */

import { clean, notNull } from '@/logic/helpers/utils';
import emitter from '@/emitter';
import PopularLocationsSearchList from '@/components/search/common/PopularLocationsSearchList';
import AllLocationsSearchList from '@/components/search/common/AllLocationsSearchList';
import ReturnLocationsSearchList from '@/components/search/common/ReturnLocationsSearchList';
import NotFoundLocationSearchList from '@/components/search/common/NotFoundLocationSearchList';
import SearchPortMixin from './../mixins/SearchPortMixin';

export default {
  name: 'SearchPortSelector',
  components: {
    PopularLocationsSearchList,
    AllLocationsSearchList,
    ReturnLocationsSearchList,
    NotFoundLocationSearchList
  },
  props: ['filter', 'locations', 'locationsByAlias', 'target', 'selections', 'previousPort', 'nextPort', 'placeholder', 'searchMode'],
  mixins: [SearchPortMixin],
  data: () => ({
    engine: window.ferryhopper.suggestions_engine,
    isFocused: false,
    isPopularPortSelected: false
  }),
  emits: ['typehintUpdate', 'onPortSelectionChange'],
  watch: {
    target: function(newVal, oldVal) {
      this.isFocused = false;
    },
    filter: function(newVal, oldVal) {
      this.updateTypehint();
    }
  },
  created: function() {
    // initialize engine locations with locations
    this.engine.setLocations(clean(this.locations));

    // onSearchInputKeyDown is raised when a tab or enter key is clicked on a
    // search input, thus indicating that the user wants to select the current
    // suggestion
    emitter.$on('onSearchInputKeyDown', index => this.onSearchInputKeyDown(index));

    // onSearchInputKeyDown is raised when a tab or enter key is clicked on a
    // search input, thus indicating that the user wants to select the current
    // suggestion
    emitter.$on('onPortInputBlur', index => this.onPortInputBlur(index));
  },
  unmounted: function() {
    // clear any typehint before leaving
    this.$emit('typehintUpdate', '');
  },
  updated() {
    // Show scrollbar depending the if the port list is overflowing or not
    const locations = document.querySelectorAll('.location-selection-list__item');
    const locationsWrapper = document.querySelectorAll('.location-selection-list');
    let locationsWrapperHeight = locationsWrapper[0].offsetHeight;
    let locationsHeight = 0;
    locations.forEach(l => (locationsHeight += l.offsetHeight));
    locationsHeight < locationsWrapperHeight ? locationsWrapper[0].classList.add('scrollbar-not-visible') : locationsWrapper[0].classList.remove('scrollbar-not-visible');
  },
  computed: {
    //------------------------------------------------------------------------
    // returns either the top suggestion from the list, or the last suggestion
    // that was shown to the user, or null
    topSuggestion() {
      let currentSuggestions = this.matchingLocations;
      return (currentSuggestions.length > 0 && currentSuggestions[0]) || this.lastSuggestion || null;
    },
    siblingPort() {
      return this.previousPort || this.nextPort;
    }
  },
  methods: {
    onSearchInputKeyDown(index) {
      let topSuggestion = this.topSuggestion;
      if (notNull(topSuggestion)) {
        this.$emit('onPortSelectionChange', topSuggestion.LocationAbbr, this.target, 'Autofill port selected');
      }
    },
    onPortInputBlur(index) {
      // if the element is focused, then the blur event triggered by the search
      // input has been caused by a click on the suggestions list. Hence, the
      // hovered item should be selected.
      // If the element is not focused, then the user has just clicked somewhere
      // outside the search input, hence select the first suggestion from the
      // list
      if (this.isFocused === true) {
      } else {
        this.onSearchInputKeyDown(index);
      }
    },
    //------------------------------------------------------------------------
    // emits a typehint in order to help the user finish what he has started
    // typing.
    // 1. If the filter is empty, it emits empty.
    // 2. If the filter matches exactly the name of a location, it emits empty
    // 3. If the filter is similar to something, it emits the alias of it
    // 4. Fallback is an empty typehint if nothing matches
    updateTypehint() {
      // when nothing has been typed, emit empty string
      if (this.filter === '') {
        // emit empty selection in order to reset the currently selected location
        this.$emit('onPortSelectionChange', '', this.target);
        // emit typehint update in order to clear the typehing
        this.$emit('typehintUpdate', '');
        return;
      }

      // on exact match, the typehint is not necessary, so emit empty string
      let exactMatch = this.matchingLocations.filter(location => {
        return location.alias === this.filter;
      });

      if (exactMatch.length > 0) {
        this.$emit('typehintUpdate', '');
        return;
      }

      // get aliases that start with exactly what the user is typing
      let possibleTypehints = this.matchingLocations.filter(location => {
        return location.alias.toLowerCase().startsWith(this.filter.toLowerCase());
      });

      if (possibleTypehints.length > 0) {
        // if the suggested typehint corresponds to a non-linked port, do
        // not show it
        if (false === possibleTypehints[0].isConnectionPossible) {
          this.$emit('typehintUpdate', '');
          return;
        }
        let hint = possibleTypehints[0].alias;
        var pattern = new RegExp(this.escapeRegExChars(this.filter), 'gi');
        this.$emit('typehintUpdate', hint.replace(pattern, this.filter));
        return;
      }

      this.$emit('typehintUpdate', '');
    },
    //------------------------------------------------------------------------
    // removes special characters to avoid regular expression errors
    escapeRegExChars(str) {
      return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    },
    //------------------------------------------------------------------------
    onSelect(abbreviation, alias, portLocationCategory, locationIndex) {
      this.$emit('onPortSelectionChange', abbreviation, this.target, portLocationCategory, locationIndex);
    }
  }
};
</script>
