<template>
  <div>
    <div class="segment-row">
      <img :src="segment.Details.CompanyIcon" class="desaturate" @error="setDefaultImage" />
      <span class="vessel">{{ segment.Details.VesselName }}</span>
    </div>

    <div class="vessel-details-row">
      <small class="time"> {{ segment.timings.DepTime }} - {{ segment.timings.ArrTime }} </small>

      <small class="duration">
        <i class="fh fh-clock"></i> {{ segment.timings.durationStr }}
        <i class="fh fh-icon-moon" aria-hidden="true" v-if="segment.timings.overnight"></i>
      </small>

      <span class="segment-amenities">
        <FhTooltip :message="cabinsTooltip">
          <i class="fh fh-bed" :class="{ unsupported: !hasCabins }"></i>
        </FhTooltip>
        <FhTooltip :message="garazeTooltip">
          <i class="fh fh-vehicles" :class="{ unsupported: !hasGaraze }"></i>
        </FhTooltip>
        <FhTooltip :message="eTicketTooltip">
          <i class="fh fh-eTicket" :class="{ invisible: !hasETicket, 'has-tooltip': hasETicket }"></i>
        </FhTooltip>
      </span>
    </div>
  </div>
</template>

<script>
import { message } from '@/logic/helpers/utils';
import { setDefaultImage } from '@/filters';

export default {
  name: 'AeItinerarySegment',
  props: ['segment'],
  methods: {
    setDefaultImage,
  },
  computed: {
    hasGaraze: function () {
      return this.segment.Details.hasGaraze;
    },
    cabinsTooltip: function () {
      return this.hasCabins ? message('tooltip.bed.on') : message('tooltip.bed.off');
    },
    hasCabins: function () {
      return this.segment.Details.hasCabins;
    },
    garazeTooltip: function () {
      return this.hasGaraze ? message('tooltip.vehicles.on') : message('tooltip.vehicles.off');
    },
    hasETicket: function () {
      return this.segment.Details.eTicket;
    },
    eTicketTooltip: function () {
      return message('tooltip.eTicket.on');
    },
  },
};
</script>
