<template>
<div>
  <slot name="header"></slot>
  <div class="search-box__body">
    <slot name="body"></slot>
  </div>
</div>
</template>

<script>
export default {
  name: 'SearchSidebox'
}
</script>
