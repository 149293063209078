<template>
  <div>
    <TravelersControl
      :class="{ pt30: !isPetsBookingActivated }"
      :icon="'users-fill'"
      :label="trans('passengers')"
      :travelerType="'passenger'"
      :travelersCount="passengers"
      :minTravelersReached="minPassengersReached"
      :maxTravelersReached="maxPassengersReached"
      @addTraveler="addTraveler"
      @removeTraveler="removeTraveler"
    />
    <TravelersControl
      v-if="isPetsBookingActivated"
      :class="'pt30'"
      :icon="'pet'"
      :label="trans('pets')"
      :travelerType="'pet'"
      :travelersCount="pets"
      :minTravelersReached="minPetsReached"
      :maxTravelersReached="maxPetsReached"
      @addTraveler="addTraveler"
      @removeTraveler="removeTraveler"
    />
    <TravelersControl
      :class="'pt30'"
      :icon="'vehicles'"
      :label="trans('vehicles')"
      :travelerType="'vehicle'"
      :travelersCount="vehicles"
      :minTravelersReached="minVehiclesReached"
      :maxTravelersReached="maxVehiclesReached"
      @addTraveler="addTraveler"
      @removeTraveler="removeTraveler"
    />
  </div>
</template>

<script>
// Vuex
import { mapState, mapActions, mapGetters } from 'vuex';
import TravelersControl from '@/components/shared/Travelers/TravelersControl';
import emitter from '@/emitter';
import { eventTravelersClicked } from '@/logic/services/events/createSharedEvents';
import { trans } from '@/filters';

export default {
  name: 'SearchTravelersPicker',
  components: {
    TravelersControl,
  },
  computed: {
    ...mapState({
      passengers: (state) => state.searchModule.passengers,
      vehicles: (state) => state.searchModule.vehicles,
      pets: (state) => state.searchModule.pets,
      isPetsBookingActivated: (state) => state.searchModule.isPetsBookingActivated,
    }),
    ...mapGetters({
      minVehiclesReached: 'searchModule/minVehiclesReached',
      minPetsReached: 'searchModule/minPetsReached',
      minPassengersReached: 'searchModule/minPassengersReached',
      maxVehiclesReached: 'searchModule/maxVehiclesReached',
      maxPetsReached: 'searchModule/maxPetsReached',
      maxPassengersReached: 'searchModule/maxPassengersReached',
    }),
  },
  methods: {
    trans,
    ...mapActions({
      increasePassengerCount: 'searchModule/increasePassengerCount',
      decreasePassengerCount: 'searchModule/decreasePassengerCount',
      increaseVehicleCount: 'searchModule/increaseVehicleCount',
      decreaseVehicleCount: 'searchModule/decreaseVehicleCount',
      increasePetCount: 'searchModule/increasePetCount',
      decreasePetCount: 'searchModule/decreasePetCount',
    }),
    isPassenger(type) {
      return type === 'passenger';
    },
    isVehicle(type) {
      return type === 'vehicle';
    },
    isPet(type) {
      return type === 'pet';
    },
    addTraveler(traveler) {
      if (this.isPassenger(traveler)) {
        this.increasePassengerCount();
        eventTravelersClicked('PASSENGER', 'ADDED');
      }
      if (this.isVehicle(traveler)) {
        this.increaseVehicleCount();
        eventTravelersClicked('VEHICLE', 'ADDED');
      }
      if (this.isPet(traveler)) {
        this.increasePetCount();
        eventTravelersClicked('PET', 'ADDED');
      }
      emitter.$emit('onSearchParametersChange');
    },
    removeTraveler(traveler) {
      if (this.isPassenger(traveler)) {
        this.decreasePassengerCount();
        eventTravelersClicked('PASSENGER', 'REMOVED');
      }
      if (this.isVehicle(traveler)) {
        this.decreaseVehicleCount();
        eventTravelersClicked('VEHICLE', 'REMOVED');
      }
      if (this.isPet(traveler)) {
        this.decreasePetCount();
        eventTravelersClicked('PET', 'REMOVED');
      }
      emitter.$emit('onSearchParametersChange');
    },
  },
};
</script>
