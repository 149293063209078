<template>
  <div>
    <AegeanNavBar />
    <div id="fh-main-app-container" class="page-content" :class="{ 'page-content--no-background': initialLoad === false }">
      <AegeanNavigationTabs />
      <template v-if="appReady">
        <!--<transition-group name="main-content-tab" mode="out-in"  tag="div">-->
        <AegeanDesktopSearchApp v-show="activeTab === 'search' || showAllApps" key="tab-1"></AegeanDesktopSearchApp>
        <hr style="height: 50px; background: gray" v-if="showAllApps" />
        <AegeanResultsApp v-show="activeTab === 'results' || showAllApps" key="tab-2"></AegeanResultsApp>
        <hr style="height: 50px; background: gray" v-if="showAllApps" />
        <AegeanSeatingApp v-show="activeTab === 'passengers' || showAllApps" key="tab-3"></AegeanSeatingApp>
        <hr style="height: 50px; background: gray" v-if="showAllApps" />
        <AegeanBookingApp v-show="activeTab === 'book' || showAllApps" key="tab-4"></AegeanBookingApp>
        <!--</transition-group>-->
      </template>
      <InfoModal />
      <RequestRefreshPopup v-show="shouldAskForPageRefresh" :variant="'aegeanair'" />
    </div>
  </div>
</template>

<script>
import { each as _each } from 'lodash-es';
import emitter from '@/emitter';
import { initializeDefaultCRS } from '@/logic/services/initializeDefaultCRS';
import AegeanNavigationTabs from '@/affiliates/aegeanair/components/AegeanNavigationTabs';
import { logException } from '@/logic/services/events/errorLogging';

import AegeanNavBar from '@/affiliates/aegeanair/AegeanNavBar';
import AegeanBookingApp from '@/affiliates/aegeanair/components/book/BookingApp';
import AegeanSeatingApp from '@/affiliates/aegeanair/components/seats/SeatingApp';
import AegeanResultsApp from '@/affiliates/aegeanair/components/results/ResultsApp';
import AegeanDesktopSearchApp from '@/affiliates/aegeanair/components/search/desktop/DesktopSearchApp';
import RequestRefreshPopup from '@/components/shared/modals/RequestRefreshPopup';

// override of global components
import { handlePromise } from '../../logic/helpers/handlePromise';
import { mapState, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'FerryhopperDesktopApp',
  components: {
    AegeanNavBar,
    AegeanDesktopSearchApp,
    AegeanNavigationTabs,
    AegeanResultsApp,
    AegeanSeatingApp,
    AegeanBookingApp,
    RequestRefreshPopup,
  },
  data: function () {
    return {
      showAllApps: false,
    };
  },
  computed: {
    ...mapState({
      appReady: (state) => state.navigationModule.appReady,
      activeTab: (state) => state.navigationModule.activeTab,
      initialLoad: (state) => state.navigationModule.initialLoad,
      shouldAskForPageRefresh: (state) => state.navigationModule.shouldAskForPageRefresh,
    }),
    ...mapGetters({
      isAutoSearch: 'searchModule/isAutoSearch',
    }),
  },

  watch: {
    shouldAskForPageRefresh: function (shouldAsk) {
      shouldAsk ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');
    },
  },

  created: async function () {
    if (this.initialLoad) {
      this.resetState();
    }

    const { response, error } = await handlePromise(initializeDefaultCRS());
    if (error) {
      logException('PrimaryDataLoading', { error });
      // TODO: We could as well show a nice error inside the loading overlay at this stage
      // and allow user to refresh
      return;
    }

    try {
      if (!this.isAutoSearch) {
        this.hideOverlay();
      }
    } catch (exception) {
      this.hideOverlay();
    }

    this.$store.commit('navigationModule/setAppReady');
    emitter.$on('onFirstResultCollected', () => this.hideOverlay());
    emitter.$on('onSearchError', () => this.hideOverlay());
  },
  methods: {
    ...mapMutations({
      resetState: 'RESET_STATE',
    }),
    hideOverlay: function () {
      try {
        let loadingOverlay = document.getElementById('loadingOverlay');
        if (loadingOverlay) {
          loadingOverlay.parentNode.removeChild(loadingOverlay);
        }
      } catch (exception) {}
    },
  },
};
</script>
