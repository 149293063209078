<template>
  <div class="row">
    <div class="col-xs-8">
      <div class="form-group">
        <label>{{ trans('aegeanlabeldeparturedate') }}:</label>
        <input
          id="date-input-0"
          :placeholder="dateholders(1)"
          :value="formattedDates(0)"
          class="form-control form-control--has-icon date-icon noselect first"
          :class="{ active: activeIndex === 0 }"
          readonly="true"
          type="text"
          @focus="onDateClicked(0)"
        />
      </div>

      <div class="form-group" :class="{ invisible: isSingleTrip }">
        <label>{{ trans('aegeanlabelarrivaldate') }}:</label>
        <input
          id="date-input-1"
          :placeholder="dateholders(2)"
          :value="formattedDates(1)"
          class="form-control form-control--has-icon date-icon noselect"
          :class="{ 'mock-placeholder': isMissingReturnDate, active: activeIndex === 1 }"
          readonly="true"
          type="text"
          @focus="onDateClicked(1)"
        />
      </div>
    </div>
    <div class="col-xs-4">
      <div class="form-group">
        <label>&nbsp;</label>
        <div class="checkbox">
          <label :class="{ checked: isSingleTrip }">
            <input name="oneway" type="checkbox" v-model="isSingleTrip" /><span class="ml8">{{ trans('cancelreturndate') }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from '@/logic/helpers/utils';
import settings from '../../../../../settings';
import { mapGetters } from 'vuex';
import { trans } from '@/filters';

export default {
  name: 'SearchFormDateInputs',
  props: {
    activeIndex: Number,
    dateInputsCount: Number,
  },
  emits: ['onReturnStatusChange', 'dateClicked'],
  data: () => ({
    format: settings.formats.localizedDateFormat,
  }),
  computed: {
    ...mapGetters({
      selectedDates: 'searchModule/getSelectedDates',
      isRoundTrip: 'searchModule/isRoundTrip',
      searchMode: 'searchModule/searchMode',
    }),
    isMissingReturnDate() {
      return this.isRoundTrip === false && this.searchMode === 'single';
    },
    // for aegean air, we start with a checkbox on the left hand side of the
    // search form. Consequently, we must use a computed property with a getter
    // and setter
    isSingleTrip: {
      get: function () {
        return !this.$store.state.searchModule.roundTrip;
      },
      set: function (value) {
        let isReturnTrip = !value;
        this.$emit('onReturnStatusChange', isReturnTrip);
      },
    },
  },
  methods: {
    trans,
    onDateClicked: function (index) {
      this.$emit('dateClicked', index);
    },
    dateholders(index) {
      return this.searchMode === 'single' ? '' : `${message('multidateholder')} ${index}`;
    },
    formattedDates: function (index) {
      // specifically for the second field in simple trips, return a different
      // text when no date has been selected
      if (index === 1 && this.isRoundTrip === false && this.searchMode === 'single') {
        return `${message('roundtrip')}?`;
      }

      if (typeof this.selectedDates[index] !== 'undefined') {
        return this.selectedDates[index].format(this.format);
      } else {
        return '';
      }
    },
  },
};
</script>
