<template>
  <div class="mb12">
    <div v-for="(count, index) of dateInputsCount" :key="'date-input-' + index">
      <div class="search-field-separator" v-if="index > 0"></div>
      <input
        :id="'date-input-' + index"
        :key="'date-input-' + index"
        :placeholder="dateholders(index + 1)"
        :value="formattedDates(index)"
        class="form-control form-control--has-icon date-icon noselect"
        :class="{ 'mock-placeholder': isMissingReturnDate && index === 1 && activeIndex !== 1, first: index === 0, active: activeIndex === index }"
        readonly="true"
        type="text"
        @focus="onDateClicked(index)"
        data-hj-allow
        :data-test="`searchDate${index}`"
      />
    </div>
  </div>
</template>

<script>
import { message } from '@/logic/helpers/utils';
import settings from '../../../settings';
import { mapGetters } from 'vuex';

export default {
  name: 'SearchDatesInputs',
  props: {
    activeIndex: Number,
    dateInputsCount: Number,
  },
  data: () => ({
    format: settings.formats.localizedDateFormatLong,
  }),
  emits: ['dateClicked'],
  computed: {
    ...mapGetters({
      selectedDates: 'searchModule/getSelectedDates',
      isRoundTrip: 'searchModule/isRoundTrip',
      searchMode: 'searchModule/searchMode',
    }),
    isMissingReturnDate() {
      return this.isRoundTrip === false && this.searchMode === 'single';
    },
  },
  methods: {
    onDateClicked(index) {
      this.$emit('dateClicked', index);
    },
    dateholders(index) {
      return this.searchMode === 'single' ? '' : `${message('multidateholder')} ${index}`;
    },
    formattedDates(index) {
      // specifically for the second field in simple trips, return a different
      // text when no date has been selected
      if (index === 1 && this.isRoundTrip === false && this.searchMode === 'single') {
        return `${message('roundtrip')}?`;
      }

      if (typeof this.selectedDates[index] !== 'undefined') {
        return this.selectedDates[index].format(this.format);
      } else {
        return '';
      }
    },
  },
};
</script>
