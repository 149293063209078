<template>
  <div class="ferryhopper-copyright text-center" v-if="isCobrandedPage">
    <hr class="mt0" />
    <span class="text-center color">{{ trans('poweredby') }}</span>
    <br />
    <img :src="fhImage('assets/logo/ferryhopper-logo-medium.png')" style="width: 135px; height: auto" />
  </div>
</template>

<script>
import { trans, fhImage } from '@/filters';

export default {
  name: 'FerryhopperCopyright',
  methods: {
    trans,
    fhImage,
  },
};
</script>
