<template>
  <div class="features-list" :class="variantClass">
    <div class="features-list__feature-item">
      <div class="features-list__feature-item__icon"></div>
      <div class="features-list__feature-item__text">
        <span class="features-list__feature-item__keyword">{{trans('slogan11')}}</span> {{trans('slogan12') }}
      </div>
    </div>
    <div class="features-list__feature-item">
      <div class="features-list__feature-item__icon"></div>
      <div class="features-list__feature-item__text">
        <span class="features-list__feature-item__keyword">{{trans('slogan21')}}</span> {{trans('slogan22') }}
      </div>
    </div>
    <div class="features-list__feature-item">
      <div class="features-list__feature-item__icon"></div>
      <div class="features-list__feature-item__text">
        <span class="features-list__feature-item__keyword">{{trans('slogan31')}}</span> {{trans('slogan32') }}
      </div>
    </div>
  </div>
</template>

<script>
import { trans } from '@/filters';

export default {
  name: 'LandingPageSidelist',
  data: function () {
    return {
      showNew: false
    }
  },
  methods: {
    trans
  },
  computed: {
    variantClass() {
      return `features-list--${this.globalCobrandedVariant}`;
    }
  }
}
</script>
