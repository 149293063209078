<template>
  <div class="container booking-flow__tab">
    <div class="row">
      <div class="col-xs-6 langing-page__search-column">
        <div id="fh-search-form" class="search-box search-box--primary">
          <div class="search-box__body">
            <div v-for="(unusedIndex, index) in destinationsCount" :key="'aegean-search-location-input-' + index">
              <label v-if="index === 0">{{ trans('aegeanlabelfrom') }}:</label>
              <label v-if="index === 1">{{ trans('aegeanlabelto') }}:</label>
              <!-- port inputs -->
              <div class="typeahead-container">
                <!-- location hint -->
                <input v-if="index === activePortIndex" class="form-control vue-typehint" tabindex="-1" v-model="activeTypehint" disabled />

                <!-- input location -->
                <input
                  type="text"
                  autocomplete="off"
                  :spellcheck="false"
                  class="form-control"
                  :id="'port-input-' + index"
                  :placeholder="portholders(index)"
                  v-model="filters[index]"
                  @focus="
                    $event.target.select();
                    onPortInputFocus(index);
                  "
                  @blur="onPortInputBlur(index)"
                  @keyup.enter.prevent="onSearchInputKeyDown(index)"
                  @keydown.tab="onSearchInputKeyDown(index)"
                />
              </div>

              <div class="fh-search-form-separator"></div>
            </div>

            <!-- dates -->
            <SearchDatesInputs :tripsCount="dateInputsCount" :activeIndex="action === actions.dates ? activeDateIndex : -1" @dateClicked="onDateInputClick" @onReturnStatusChange="onCustomReturnStatusChange">
            </SearchDatesInputs>

            <div class="fh-search-form-separator"></div>

            <!-- passengers & vehicles -->
            <div class="form-group" id="passengers-select-input">
              <div class="row">
                <div class="col-xs-6">
                  <div class="icon-input">
                    <span class="icon-input__icon"><i class="fh" :class="passengersCount > 1 ? 'fh-users-fill' : 'fh-user-fill'"></i></span>
                    <input class="icon-input__input" readonly="true" :value="passengersString" @focus="onPassengersInputFocus()" />
                  </div>
                </div>
                <div class="col-xs-6">
                  <div class="icon-input">
                    <span class="icon-input__icon"><i class="fh fh-vehicles"></i></span>
                    <input class="icon-input__input" readonly="true" :value="vehiclesString" @focus="onPassengersInputFocus()" />
                  </div>
                </div>
              </div>
            </div>

            <SearchButtonBar :isWaiting="isWaiting" :error="error" @onSearchClicked="onSearchClicked()" />
          </div>
        </div>
      </div>
      <div class="col-xs-6 langing-page__search-column">
        <div class="search-state-indicator hidden-xs" :class="{ highlight: !isFirstLoad }" :style="{ top: activeElementYCoordinate }"></div>
        <div class="search-box search-box--secondary noselect landing-page-sidebox" :style="{ minHeight: formAutoHeight }">
          <!-- show default welcome list if action not performed -->
          <SearchSidebox v-if="action === actions.nothing">
            <template #header>
              <SearchSideboxHeader :isHeader="true" :title="trans('desktoplandingheading')"> </SearchSideboxHeader>
            </template>
            <template #body>
              <LandingPageSidelist />
              <FerryhopperCopyright />
            </template>
          </SearchSidebox>

          <SearchSidebox v-if="action === actions.port">
            <template #header>
              <SearchSideboxHeader :title="selectPortTitle"> </SearchSideboxHeader>
            </template>
            <template #body>
              <SearchPortSelector
                :filter="filters[activePortIndex]"
                :locations="locations"
                :target="activePortIndex"
                :selections="selectedPorts"
                :placeholder="portholders(activePortIndex)"
                :previousPort="selectedPorts[activePortIndex - 1]"
                :nextPort="selectedPorts[activePortIndex + 1]"
                @typehintUpdate="onTypehintUpdate"
                @onPortSelectionChange="onPortSelectionChange"
              >
              </SearchPortSelector>
            </template>
          </SearchSidebox>

          <SearchSidebox v-if="action === actions.dates">
            <template #header>
              <SearchSideboxHeader :title="selectDateTitle"> </SearchSideboxHeader>
            </template>
            <template #body>
              <DesktopTripDatepicker :activeDateIndex="activeDateIndex" />
            </template>
          </SearchSidebox>

          <SearchSidebox v-if="action === actions.passengers">
            <template #header>
              <SearchSideboxHeader :title="trans('passengersmodaltitle')"> </SearchSideboxHeader>
            </template>
            <template #body>
              <AegeanSearchTravelersPicker />
            </template>
          </SearchSidebox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emitter from '@/emitter';
import DesktopSearchApp from '@/components/search/desktop/DesktopSearchApp';
import SearchButtonBar from '@/components/search/desktop/SearchButtonBar';
import SearchDatesInputs from '@/affiliates/aegeanair/components/search/desktop/SearchDatesInputs';
import SearchPortSelector from '@/affiliates/aegeanair/components/search/desktop/SearchPortSelector';
import AegeanSearchTravelersPicker from './AegeanSearchTravelersPicker';
import SuggestionsEngine from '@/logic/managers/SuggestionsEngine';
import { trans } from '@/filters';

const SUGGESTIONS_LIST_LIMIT = 8;

window.ferryhopper = window.ferryhopper || {};
window.ferryhopper.suggestions_engine = new SuggestionsEngine([], SUGGESTIONS_LIST_LIMIT);

export default {
  extends: DesktopSearchApp,
  components: {
    SearchButtonBar,
    SearchDatesInputs,
    SearchPortSelector,
    AegeanSearchTravelersPicker,
  },
  methods: {
    trans,
    onCustomReturnStatusChange: function (status) {
      this.$store.dispatch('searchModule/setRoundTrip', status);
      emitter.$emit('onSearchParametersChange');
      this.activeDateIndex = 0;
    },
  },
};
</script>
